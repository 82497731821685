/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as React from "react";

import * as rengo_utils from "@/lib/rengo_utils";
import * as rengo_balancer from "@/lib/rengo_balancer";
import { alert } from "@/lib/swal_config";
import { errorAlerter } from "@/lib/misc";
import { _, pgettext } from "@/lib/translate";
import { del, get } from "@/lib/requests";
import { useUser } from "@/lib/hooks";

import { profanity_filter } from "@/lib/profanity_filter";
import { challenge_text_description } from "@/components/ChallengeModal";

import { Card } from "@/components/material";
import { FabX } from "@/components/material";

import { ChallengeLinkButton } from "@/components/ChallengeLinkButton";
import { RengoManagementPane } from "@/components/RengoManagementPane";
import { RengoTeamManagementPane } from "@/components/RengoTeamManagementPane";
import { TimeControlTypes } from "@/components/TimeControl";
import { RuleSet } from "@/lib/types";

// We face two different challenge schemas because the challenge data we have is from the
// 'open invites' route, which is generated by serializers.py, in the same way as the direct
// challenges list in Challenges.tsx, but we are reusing `RengoManagementPane` from Play.tsx,
// which is fed from seekgraph - which does not user the serializers.
// An alternative would have been(would be ?) to add a new serializer that generates the seekgraph
// dto schema, but proliferating serializers is not attractive, and it would be a challenging task
// ... plus only a few of the fields  are actually needed here!

type ChallengeDTO = rest_api.OpenChallengeDTO;
type Challenge = socket_api.seekgraph_global.Challenge;

type RengoParticipantsDTO = rest_api.RengoParticipantsDTO;
type TimeControlSystem = TimeControlTypes.TimeControlSystem;

function challengeDtoToSeekgraphChallengeSubset(c: ChallengeDTO, user_id: number): Challenge {
    if (!c.game) {
        throw new Error("ChallengeDTO has no game");
    }

    return {
        challenge_id: c.id,
        created: c.created,
        user_id: c.challenger.id as number, // number;
        username: c.challenger.username, // string;

        rengo: c.game.rengo, // boolean;
        rengo_nominees: c.rengo_nominees, // number[], // array of player ids
        rengo_black_team: c.rengo_black_team, // number[], // array of player ids
        rengo_white_team: c.rengo_white_team, // number[], // array of player ids
        rengo_participants: c.rengo_participants, // number[], // array of player ids
        rengo_casual_mode: c.game.rengo_casual_mode, // boolean;
        user_challenge: c.challenger.id === user_id,
        time_control_parameters: JSON.parse(c.game.time_control_parameters as string),
        time_control: c.game.time_control as TimeControlSystem,
        game_name: c.game.name,
        rules: c.game.rules as RuleSet, // import("../lib/types").RuleSet;
        width: c.game.width, // number;
        height: c.game.height, // number;
        challenger_color: c.challenger_color, // "black" | "white" | "automatic";
        handicap: c.game.handicap, // number;
        ranked: c.game.ranked, // boolean;
        uuid: c.uuid, // string;

        // These fields are not used by us, so we don't need to bother with them
        // (which is just as well, since many are not in ChallengeDTO)
        rank: -999, // number;
        pro: 0, // 0 | 1;
        min_rank: -999, // number;
        max_rank: -999, // number;
        game_id: -999, // number;
        name: "", // string;
        komi: -999, // number;
        disable_analysis: true, // true;
        time_per_move: -999, // number;
        rengo_auto_start: -999, // number;

        invite_only: false, // boolean;
    };
}

export function InviteList(): JSX.Element {
    const [invites, setInvites] = React.useState<Challenge[]>([]);
    const [show_details, setShowDetails] = React.useState<Challenge | null>(null);
    const [lock, setLock] = React.useState(false);

    const user = useUser();

    const removeChallenge = (challenge: Challenge) => {
        setInvites(invites.filter((c) => c.challenge_id !== challenge.challenge_id));
    };

    const deleteChallenge = (challenge: Challenge) => {
        del(`challenges/${challenge.challenge_id}`)
            .then(() => {
                removeChallenge(challenge);
            })
            .catch(errorAlerter);
    };

    const cancelRengoChallenge = (challenge: Challenge) => {
        setShowDetails(null);

        alert
            .fire({
                text: _("Are you sure you want to delete this rengo challenge?"),
                showCancelButton: true,
                confirmButtonText: _("Yes"),
                cancelButtonText: _("Cancel"),
            })
            .then(({ value: yes }) => {
                if (yes) {
                    rengo_utils
                        .cancelRengoChallenge(challenge)
                        .then(() => {
                            removeChallenge(challenge);
                        })
                        .catch(errorAlerter);
                }
            })
            .catch(() => 0);
    };

    const unNominate = (challenge: Challenge) => {
        setShowDetails(null);
        rengo_utils
            .unNominate(challenge)
            .then(() => {
                removeChallenge(challenge);
            })
            .catch(errorAlerter);
    };

    const updateRengoParticipants = (challenge: Challenge, participants: RengoParticipantsDTO) => {
        const invites_update = invites.filter((c) => c.challenge_id !== challenge.challenge_id);
        challenge.rengo_nominees = participants.rengo_nominees;
        challenge.rengo_black_team = participants.rengo_black_team;
        challenge.rengo_white_team = participants.rengo_white_team;
        setInvites([challenge, ...invites_update]);
    };

    const nominateForRengoChallenge = (challenge: Challenge) => {
        rengo_utils
            .nominateForRengoChallenge(challenge)
            .then((participants) => {
                updateRengoParticipants(challenge, participants);
            })
            .catch(errorAlerter);
    };

    const assignToTeam = (player_id: number, team: string, challenge: Challenge): Promise<void> => {
        return rengo_utils
            .assignToTeam(player_id, team, challenge)
            .then((participants) => {
                updateRengoParticipants(challenge, participants);
            })
            .catch(errorAlerter);
    };

    // note, this probably can't get invoked for a normal user in practice,
    // because the kick button only appears for moderators, but this is on
    // the person's home page.   TBD: solve how moderators help in this case!
    const kickRengoUser = (player_id: number): Promise<void> => {
        return rengo_utils
            .kickRengoUser(player_id)
            .then(() => {
                // dump rengo challenges, because this person has been kicked.
                setInvites(invites.filter((c) => !c.rengo));
            })
            .catch(errorAlerter);
    };

    const unassignPlayers = (challenge: Challenge): Promise<void> => {
        return rengo_balancer
            .unassignPlayers(challenge)
            .then((participants) => {
                updateRengoParticipants(challenge, participants);
            })
            .catch(errorAlerter);
    };

    const balanceTeams = (challenge: Challenge): Promise<void> => {
        return rengo_balancer
            .balanceTeams(challenge)
            .then((participants) => {
                updateRengoParticipants(challenge, participants);
            })
            .catch(errorAlerter);
    };

    const setTeams = (teams: RengoParticipantsDTO, challenge: Challenge): Promise<void> => {
        return rengo_utils
            .setTeams(teams)
            .then((participants) => {
                updateRengoParticipants(challenge, participants);
            })
            .catch(errorAlerter);
    };

    const startRengoChallenge = (challenge: Challenge) => {
        setShowDetails(null);
        return rengo_utils
            .startOwnRengoChallenge(challenge)
            .then(() => {
                removeChallenge(challenge);
            })
            .catch(errorAlerter);
    };

    const showRengoManagementPane = (challenge: Challenge) => {
        setShowDetails(challenge);
    };

    React.useEffect(
        () => {
            get("me/challenges/invites", { page_size: 30 })
                .then((res) => {
                    // The result contains a list of ChallengeDTO objects
                    // We need a list of Challenge objects to work with for Rengo panes etc...
                    const invite_list: Challenge[] = [];
                    for (const challenge of res.results as ChallengeDTO[]) {
                        invite_list.push(
                            challengeDtoToSeekgraphChallengeSubset(challenge, user.id),
                        );
                    }
                    setInvites(invite_list);
                })
                .catch((err) => {
                    console.error("Error receiving invite list:", err);
                });
        },
        [] /* run once */,
    );

    /* render */
    return (
        <div className="InviteList">
            {invites.length > 0 && (
                <h2>
                    {pgettext(
                        "The list of this person's current invite-only challenges",
                        "Your Open Invites",
                    )}
                </h2>
            )}
            <div className="challenge-cards">
                {invites.map((challenge) => {
                    return (
                        <Card key={challenge.challenge_id}>
                            <div className="name-and-buttons">
                                <div
                                    className="name"
                                    title={profanity_filter(challenge.game_name || "")}
                                >
                                    <h4>"{profanity_filter(challenge.game_name || "")}"</h4>
                                    <ChallengeLinkButton uuid={challenge.uuid} />
                                </div>
                                <div className="fab-section">
                                    {(challenge.rengo || null) && (
                                        <button
                                            className="primary sm"
                                            onClick={() => showRengoManagementPane(challenge)}
                                        >
                                            {challenge.user_id === user.id
                                                ? pgettext(
                                                      "Manage rengo teams in a challenge",
                                                      "Manage",
                                                  )
                                                : pgettext(
                                                      "Look at rengo teams in a challenge",
                                                      "View",
                                                  )}
                                        </button>
                                    )}
                                    {((challenge.user_id === user.id && !challenge.rengo) ||
                                        null) && (
                                        <FabX onClick={() => deleteChallenge(challenge)} />
                                    )}
                                </div>
                            </div>
                            <div>{challenge_text_description(challenge as any)}</div>
                        </Card>
                    );
                })}
            </div>
            {(show_details || null) && (
                <RengoManagementPane
                    challenge_id={show_details!.challenge_id}
                    user={user}
                    rengo_challenge_list={invites}
                    startRengoChallenge={startRengoChallenge}
                    cancelChallenge={cancelRengoChallenge}
                    withdrawFromRengoChallenge={unNominate}
                    joinRengoChallenge={nominateForRengoChallenge}
                    lock={lock}
                >
                    <RengoTeamManagementPane
                        user={user}
                        challenge_id={show_details!.challenge_id}
                        challenge_list={invites}
                        moderator={user.is_moderator}
                        show_chat={true}
                        assignToTeam={assignToTeam}
                        kickRengoUser={kickRengoUser}
                        unassignPlayers={unassignPlayers}
                        balanceTeams={balanceTeams}
                        setTeams={setTeams}
                        locked={lock}
                        lock={setLock}
                    />
                </RengoManagementPane>
            )}
        </div>
    );
}
