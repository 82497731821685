/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

// Code sourced from react-dndkit-multiple-containers example
// https://codesandbox.io/p/sandbox/react-dndkit-multiple-containers-6wydy9?file=%2Fsrc%2Fexamples%2FSortable%2FMultipleContainers.tsx%3A240%2C1

import React, { forwardRef } from "react";
import classNames from "classnames";

export interface Props {
    children: React.ReactNode;
    columns?: number;
    style?: React.CSSProperties;
    horizontal?: boolean;
}

export const List = forwardRef<HTMLUListElement, Props>(
    ({ children, columns = 1, horizontal, style }: Props, ref) => {
        return (
            <ul
                ref={ref}
                style={
                    {
                        ...style,
                        "--columns": columns,
                    } as React.CSSProperties
                }
                className={classNames("List", horizontal && "horizontal")}
            >
                {children}
            </ul>
        );
    },
);
